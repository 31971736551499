/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {ViewImageModal} from '../../../../app/modules/apps/data-administration/data-admininstration-list/table/columns/ViewImageModal'

type Props = {
  className: string
  data?: any
}

const TablesWidget10: React.FC<Props> = ({className, data = []}) => {
  const [showImageModal, setshowImageModal] = useState({
    show: false,
    clicked: '',
  })

  if (showImageModal.show) {
    return (
      <ViewImageModal
        close={() => setshowImageModal({show: false, clicked: ''})}
        images={showImageModal.clicked}
      />
    )
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Receive</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Last 10 receive</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Facility Name</th>
                <th className='min-w-140px'>Location</th>
                <th className='min-w-120px'>Material</th>
                {/* <th className='min-w-120px'>Icon</th> */}
                <th className='min-w-120px'>Qty</th>
                <th className='min-w-100px'>Images</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {data?.length &&
                data?.slice(0, 10).map((eachData, eachIndex) => {
                  const teamImage = eachData?.images?.filter((x) => x.imageType === 'TEAM_IMAGE')[0]
                  return (
                    <tr key={eachIndex + 1}>
                      <td>
                        <div className='d-flex align-items-center'>
                          {eachData?.centreInfo?.name}
                          {/*  <div className='symbol symbol-45px me-5'>
                            <img src={teamImage?.url || ''} alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {eachData?.customerInfo?.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {eachData?.customerInfo?.address?.country}
                            </span>
                          </div> */}
                        </div>
                      </td>
                      <td>
                        {eachData?.centreInfo?.address?.street}
                        {/* <a
                          href='#'
                          className='text-success fw-bold text-hover-primary d-block fs-6'
                        >
                          {eachData?.dispatchInfo?.latitute} , {eachData?.dispatchInfo?.longitute}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {eachData?.dispatchInfo?.street}
                        </span> */}
                      </td>
                      <td>
                        <a href='#' className='text-dark text-hover-primary d-block fs-6'>
                          {/* {eachData?.orderDetails?.quantity} */}
                          {eachData?.orderDetails[0]?.categoryName}
                        </a>
                        {/*  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Houses &amp; Hotels
                        </span> */}
                      </td>
                      {/* <td>
                        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
                          <div className='symbol-label'>
                            <img
                              src={`${eachData?.orderDetails?.[0]?.categoryIcon || ''}`}
                              alt={eachData?.name}
                              className='w-100'
                            />
                          </div>
                        </div>
                      </td> */}
                      <td>
                        <span className='text-primary fs-7 fw-bold'>
                          {eachData?.orderDetails?.[0]?.items?.reduce(
                            (acc, curr) => acc + curr.quantity,
                            0
                          ) + ' kg' || 0}
                        </span>
                      </td>
                      <td>
                        <div className='d-flex '>
                          <span
                            className='fs-8  text-primary'
                            onClick={() =>
                              setshowImageModal({
                                show: true,
                                clicked: eachData.images,
                              })
                            }
                          >
                            {' '}
                            click to view
                          </span>
                          {/*  <div
                            onClick={() =>
                              setshowImageModal({
                                show: true,
                                clicked: eachData.images,
                              })
                            }
                          >
                            click to view
                          </div> */}
                        </div>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TablesWidget10}
