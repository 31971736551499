/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {
  generateAddress,
  configCategoryMap,
  toAbsoluteUrl,
} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'

type Props = {
  user: any
  showImage?: boolean
  mapData?: string
  showImageOnly?: boolean
}

const UserInfoCell: FC<Props> = ({
  user,
  showImageOnly = false,
  showImage = false,
  mapData = '',
}) => {
  const accessNestedProperty = (obj, path) => {
    return path.split('[').reduce((acc, key) => {
      return acc && acc[key.replace(']', '')]
    }, obj)
  }
  const configMapData = configCategoryMap
  const returnData = () => {
    if (mapData.includes('[')) return accessNestedProperty(user, mapData)
    switch (mapData) {
      case 'processName':
        return <span className={`badge badge-light-info fs-7 fw-bold`}>{user[mapData] || ''}</span>
      case 'facilityType':
        return user?.facilityType?.toUpperCase()
      case 'dimension':
        return (
          <div>
            {user?.width?.toString()}
            {((user?.width || user?.width == 0) && ' * ') || ''} {user?.height?.toString()}{' '}
            {user.measurementUnit}
          </div>
        )
      case 'categoryMap':
        return <div>{configMapData[user.categoryId] || ''}</div>
      case 'format':
        return user.companyDetails.format || 'OWN'
      case 'contactDetail':
        return (
          <div>
            <div>{user.personalDetails.mobile}</div>
            <div>{user.personalDetails.email}</div>
          </div>
        )
      case 'icon':
        return (
          <div className='symbol symbol-circle'>
            {user?.icon ? <img src={user?.icon || ''} alt={user.name} /> : ''}
          </div>
        )
      case 'categoryIcon':
        return (
          <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
            <div className='symbol-label'>
              <img
                src={`${user?.orderDetails?.[0]?.categoryIcon || ''}`}
                alt={user.name}
                className='w-100'
              />
            </div>
          </div>
        )
      case 'categoryName':
        return (
          <span className={`badge badge-light-info fs-7 fw-bold`}>
            {user?.orderDetails?.[0]?.categoryName || user.categoryName || ''}
          </span>
        )
      case 'quantityDeposit':
        const totalQuantity =
          user?.orderDetails?.[0]?.items?.reduce((acc, curr) => {
            return acc + curr.quantity
          }, 0) + ' kg' || 0
        return <span className='text-primary fs-7 fw-bold'>{totalQuantity}</span>
      case 'pickup_quantity':
        return user?.orderDetails?.[0].items?.map((x: any, ind: number) => (
          <span className='text-primary fs-7 fw-bold' key={ind + 1 + ''}>
            {x.quantity + ' kg'}
          </span>
        ))
      case 'pickup_quantity_item':
        return user?.orderDetails?.[0].items?.map((x: any, ind: number) => (
          <div key={ind + 1 + ''}>
            {mapData === 'pickup_quantity_item' ? x.itemName : x.quantity}
          </div>
        ))
      case 'pickup_address':
        return clipText(generateAddress(user.pickupInfo?.address))
      case 'address':
        // return generateAddress(user.address || user.pickupInfo?.address)

        const addressConcat = generateAddress(
          user.address || user.pickupInfo?.address || user.dropOffPointInfo?.address
        )
        return (
          <div data-bs-toggle='tooltip' title={addressConcat}>
            {clipText(addressConcat, 25)}
          </div>
        )
      case 'manager':
        return user.companyDetails.name
      case 'geoLocation':
        // return user.address.latitute + ' , ' + user.address.longitute
        return (
          <>
            <div>{user.address.latitute}</div>
            <div>{user.address.longitute}</div>
          </>
        )
      case 'pickupCompletedAt':
        return (
          (user?.pickupInfo?.pickupCompletedAt &&
            new Date(+user?.pickupInfo?.pickupCompletedAt || new Date()).toLocaleDateString()) ||
          ''
        )
      case 'pickupDate':
        return new Date(+user?.pickupInfo?.pickupDate || new Date()).toLocaleDateString()
      case 'paymentMode':
        return (
          <span
            className={`badge badge-light-${
              user?.[mapData] === 'CASH'
                ? 'info'
                : user?.[mapData] === 'WALLET'
                ? 'primary'
                : 'success'
            } fs-7 fw-bold`}
          >
            {user?.[mapData] || user?.personalDetails?.[mapData]}
          </span>
        )
      case 'street':
        return user.address.street
      case 'createdAt':
      case 'collectionDate':
        return new Date(+user?.[mapData] || new Date()).toLocaleDateString()
      case 'inputMaterialsQuantity':
      case 'productionItemDetailsQuantity':
        return user[
          mapData === 'inputMaterialsQuantity' ? 'inputMaterials' : 'productionItemDetails'
        ]?.map((x: any, ind: number) => (
          <div key={ind + 1 + ''}>{x.quantity || x.inputQuantity}</div>
        ))
      case 'cityProvince':
        return user.address.city + ' , ' + user.address.state
      case 'inMaterials':
      case 'outMaterials':
      case 'inputMaterials':
      case 'productionItemDetails':
        return user[mapData]?.map((x: any, ind: number) => (
          <div key={ind + 1 + ''}>{x.name || x.itemName || x.inputMaterialName}</div>
        ))
      case 'bussinessImage':
      case 'personalImage':
        return (
          <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
            <div className='symbol-label'>
              <img
                src={`${user?.documents?.[mapData === 'bussinessImage' ? 0 : 1] || ''}`}
                alt={user.name}
                className='w-100'
              />
            </div>
          </div>
        )
      case 'operatingHours':
        return <span className='badge badge-light-success fs-7 fw-bold'>{user?.[mapData]}</span>
      case 'teamSize':
        return <span className='badge badge-light-primary fs-7 fw-bold'>{user?.[mapData]}</span>
      case 'wastage':
        return <span className='badge badge-light-danger fs-7 fw-bold'>{user?.[mapData]}</span>
      case 'status':
        const statusData = user?.[mapData] || user?.personalDetails?.[mapData]
        switch (statusData) {
          case 'quantityDeposit':
            return <span className='text-primary fs-7 fw-bold'>+28%</span>
          case 'Pickup Completed':
          case 'Completed':
            return <span className='badge badge-light-success fs-7 fw-bold'>{statusData}</span>
          case 'Accepted':
            return <span className='badge badge-light-danger fs-7 fw-bold'>{statusData}</span>
          case 'Pickup Assigned':
            return <span className='badge badge-light-primary fs-7 fw-bold'>{statusData}</span>

          default:
            return user?.[mapData] || user?.personalDetails?.[mapData]
        }
      default:
        return typeof user[mapData] === 'boolean'
          ? user[mapData]
            ? 'Yes'
            : 'No'
          : user?.[mapData] || user?.personalDetails?.[mapData] || ''
    }
  }
  return (
    <div className='align-items-center'>
      {showImageOnly && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <div className='symbol-label'>
            <img
              src={
                user.icon ||
                toAbsoluteUrl(
                  `${
                    user?.avatar || user?.personalDetails?.profileImage || user?.kycDocument?.docUrl
                  }`
                )
              }
              alt={user.name}
              className='w-100'
            />
          </div>
        </div>
      )}
      {showImage && (
        <div className='symbol symbol-circle swymbol-50px overflow-hidden me-3'>
          <a href='#'>
            {user.avatar ? (
              <div className='symbol-label'>
                <img
                  src={
                    user.icon ||
                    toAbsoluteUrl(
                      `${
                        user?.avatar ||
                        user?.personalDetails?.profileImage ||
                        user?.kycDocument?.docUrl
                      }`
                    )
                  }
                  alt={user.name}
                  className='w-100'
                />
              </div>
            ) : (
              <div
                className={clsx(
                  'symbol-label fs-3',
                  `bg-light-${user.initials?.state}`,
                  `text-${user.initials?.state}`
                )}
              >
                {user.initials?.label}
              </div>
            )}
          </a>
        </div>
      )}
      {!showImageOnly && (
        <div className='d-flex flex-column'>
          <a href='#' className='text-gray-800 text-hover-primary mb-1'>
            {clipText(returnData(), 30)}
          </a>
        </div>
      )}
    </div>
  )
}

function clipText(text, maxLength = 25, clipper = '...') {
  if (!text) return text
  return text.length > maxLength ? text.slice(0, maxLength - clipper.length) + clipper : text
}

export {UserInfoCell}
